@import "_variables.scss";

.loading-animation {
  z-index: 1;
  @include centerBoth();
  width: 50px;

  .animation {
    margin: 0 auto;
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 120%;
    text-align: center;
    font-size: 10px;
  }

  .animation > div {
    background-color: none;
    height: 100%;
    width: 20%;
    padding: 0 2%;
    position: absolute;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;

    &:after {
      content: '';
      background-color: $guitareoGreen;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  }

  .animation .rect1 {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
    left: 0;
  }

  .animation .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
    left: 20%;
  }

  .animation .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
    left: 40%;
  }

  .animation .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
    left: 60%;
  }

  .animation .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
    left: 80%;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4)
    }
    20% {
      -webkit-transform: scaleY(1.0)
    }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
}