@import "_variables.scss";

footer {
  z-index: 2;
  position: relative;
  .footer-top {
    background:#1A232C;
    padding:25px 0 10px;
    margin-top: 30px;
    text-align:center;
    @include tablet {
      padding:35px 0 10px;
      text-align:left;
    }
    @include desktop {
      padding:45px 0 25px;
    }
    h1 {
      color:#8c9194;
      font:700 17px/1em $robotoCondensed;
      margin:0 auto;
      text-transform:uppercase;
      padding:10px 0;
      text-overflow:ellipsis;
      overflow:hidden;
      white-space:nowrap;
      @include desktop {
        font-size:21px;
        padding:17px 0;
      }
      a {
        color:inherit;
      }
    }
    p {
      color:#8c9194;
      font:400 12px/1.2em $opensans;
      margin:0 auto;
      @include tablet {
        font-size:13px;
      }
      @include desktop {
        font-size:16px;
      }
      a {
        display:inline-block;
        vertical-align:text-bottom;
      }
    }
    a {
      color:#8c9194;
      font:400 12px/1.2em $opensans;
      display:block;
      margin:0 auto 15px;
      text-overflow:ellipsis;
      overflow:hidden;
      white-space:nowrap;
      @include tablet {
        font-size:13px;
      }
      @include desktop {
        font-size:16px;
        margin:0 auto 10px;
      }
      &:hover {
        color:#979EA6;
        text-decoration: none;
      }
    }
    .contact {
      img {
        opacity:0.5;
        margin:0 auto 7px;
        width:190px;
        @include tablet {
          width:160px;
        }
        @include desktop {
          width:240px;
          margin:0 auto 10px;
        }
      }
    }
  }
  .footer-bottom {
    background:#000;
    padding:10px 0 0;
    @include tablet {
      padding:7px 0;
    }
    p {
      color:#8c9194;
      margin:0 auto;
      font:400 12px/50px $opensans;
      @include tablet {
        font-size:13px;
      }
      @include desktop {
        font-size:16px;
      }
      a {
        line-height:1em;
      }
    }
    a {
      color:#8c9194;
      display:inline-block;
      margin:0 auto;
      font:400 12px/50px $opensans;
      @include tablet {
        font-size:13px;
      }
      @include desktop {
        font-size:16px;
      }
      &:hover {
        color:#979EA6;
        text-decoration: none;
      }
    }
    .copyright {
      text-align:center;
      @include tablet {
        text-align:left;
      }
    }
    .social-links {
      text-align:center;
      @include tablet {
        text-align:right;
      }
      a {
        background:#1A232C;
        border-radius:50%;
        display:inline-block;
        text-align:center;
        margin:0 2.5%;
        width:50px;
        height:50px;
        line-height:50px;
        font-size:30px;
        @include tablet {
          margin:0 0 0 15px;
          width:50px;
          height:50px;
          line-height:50px;
          font-size:26px;
        }
        &:hover {
          background:#1F2933;
        }
        &:first-child {
          @include tablet {
            margin:0;
          }
        }
      }
    }
  }
}