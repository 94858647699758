////////////////////////////
//   VARIABLES & MIXINS
///////////////////////////

//FONTS

$bree:'Bree Serif', serif;
$opensans:'Open Sans', sans-serif;
$robotoCondensed: 'Roboto Condensed', sans-serif;

//COLORS
$glBlue:#0b76db;
$glYellow:#f4ab03;
$guitareoGreen:#00C9AC;
$guitareoGreenHover:#00E0BF;
$beginnerBlue:#06cfea;
$rhythmGreen:#27ae60;
$leadYellow:#e4ca24;
$bluesBlue:#6678fd;

@mixin enableHardware {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
//MEDIA QUERIES
$tablet-width: 768px;
$desktop-width: 991px;
$large-desktop-width: 1200px;
$menuhide: 1730px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

@mixin hidemenu {
  @media (min-width: #{$menuhide}) {
    @content;
  }
}

@mixin highdpi {
  @media    only screen and (-webkit-min-device-pixel-ratio: 1.3),
  only screen and (-o-min-device-pixel-ratio: 13/10),
  only screen and (min-resolution: 120dpi) {
    @content;
  }
}

//POSITIONAL MIXINS

@mixin centerVertical {
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
}

@mixin centerHorizontal {
  position:absolute;
  left:50%;
  transform:translateX(-50%);
  -webkit-transform:translateX(-50%);
  -moz-transform:translateX(-50%);
}

@mixin centerBoth {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  -webkit-transform:translate(-50%,-50%);
  -moz-transform:translate(-50%,-50%);
}