@import "_variables.scss";
@import "_icons.scss";

body {
  background:#FFF;
  padding-top:40px;
  @include tablet {
    padding-top:50px;
  }
  @include desktop {
    padding-top:60px;
  }
}
.no-padding {
  padding:0;
}
.half-padding {
  padding-left: 7px;
  padding-right: 7px;
  @include tablet {
    padding-left: 15px;
    padding-right: 15px;

  }
}
.top-bar {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:40px;
  background:#000;
  padding:0;
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
  z-index:101;

  @include tablet {
    height:50px;
  }
  @include desktop {
    height:60px;
  }

  &.menu-collapsed {
    .middle-section {
      padding-right:15px;
    }
    .right-section {
      width:0;
      min-width:0;
    }
  }

  .nav-section {
    display:flex;
    //flex-direction:column;
    justify-content:center;
    //flex-flow:column;
    flex-wrap:nowrap;
    align-items:center;
  }

  .logo {
    padding:0 0 0 7px;
    height:100%;

    @include tablet {
      padding:0 12px;
    }
    a {
      font-size: 0;
    }
    img {
      height:25px;
      width:auto;

      @include tablet {
        height:33px;
      }

      @include desktop {
        height:40px;
      }
    }
  }
  .middle-section {
    flex-grow:1;
  }
  .right-section {
    width:160px;
    overflow:hidden;

    @include tablet {
      width:350px;
    }
    .menu-toggle {
      cursor:pointer;
      transition:all .1s ease-in-out;
      position:relative;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 10px 15px;
      @include tablet {
        padding: 12px 25px;

      }

      &.active {
        i {
          background:none;

          &:before {
            top:0;
            transform:rotate(45deg);
          }
          &:after {
            bottom:0;
            transform:rotate(-45deg);
          }
        }
      }
      p {
        margin:0;
        text-align:center;
        font:400 14px $opensans;
        text-transform:uppercase;
        color:#899596;

        i {
          display: inline-block;
          background: #899596;
          position: relative;
          width: 18px;
          height: 3px;
          vertical-align:middle;
          transition:background .2s ease-in-out;

          &:before, &:after {
            background: #899596;
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            left: 0;
            transition:all .2s ease-in-out;
          }
          &:before {
            top:-7px;
          }
          &:after {
            bottom:-7px;
          }
        }
      }

    }

    a {
      display:inline-block;
      flex:1;
      color:#899596;
      padding:3px;
      //border:1px solid #fff;
      border-radius:5px;
      text-align:center;
      width:auto;
      cursor:pointer;
      text-decoration:none;
      font:400 12px $opensans;
      transition:background .1s ease-in-out;

      @include tablet {
        padding:5px;
        font-size:14px;
      }

      @include desktop {
        font-size:16px;
      }

      &:hover, &.active {
        background:#222;
        color: #fff;
      }
    }
  }

  .nav-side-bar {
    position:fixed;
    right:0;
    top:40px;
    height:calc(100% - 40px);
    width:280px;
    background:#fff;
    z-index:100;
    transform:translateX(100%);
    transition:transform .2s ease-in-out;

    @include tablet {
      width:325px;
      top:50px;
      height:calc(100% - 50px);
    }
    @include desktop {
      top:60px;
      height:calc(100% - 60px);
    }

    &.active {
      transform:translateX(0);
    }

    a {
      display: block;
      text-decoration: none;
    }

    .lesson-links {
      max-height:0;
      overflow:hidden;
      transition:all .1s linear;
      -webkit-transform:translateZ(0);
      transform:translateZ(0);
      background:#F7F7F7;
      &.active {
        max-height:350px;
      }
      .nav-item {
        padding-left: 30px;
        font-size: 15px;
        border-bottom: 1px solid rgba(126, 126, 126, .2);
      }
    }

    .nav-item {
      display:block;
      padding:12px 15px;
      font:400 16px/18px $opensans;
      border-bottom:1px solid #f2f2f2;
      transition:background .1s ease-in-out;
      cursor:pointer;
      text-decoration:none;
      color:#000;
      user-select:none;

      @include tablet {
        padding: 12px 20px;
      }

      @include desktop {
        &:hover {
          background:#f2f2f2;
        }
      }

      i {
        float:right;
        line-height:18px;
        font-size: 20px;
        color:$glYellow;
        transition: transform .3s;
        &.rotate {
          -webkit-transform:rotate(180deg);
          transform:rotate(180deg);
        }
      }
    }

    .secondary-nav-item {
      display:block;
      padding:5px 15px;
      font:400 12px $opensans;
      color:#000;
      cursor:pointer;

      @include tablet {
        padding: 5px 20px;
      }

      i {
        margin-left:5px;
      }
    }
    .shim {
      display:block;
      height:15px;
    }
  }
}

.nav-overlay {
  position:fixed;
  top:0;
  left:0;
  background:rgba(0,0,0,.3);
  width:100%;
  height:100%;
  z-index:99;
  display:none;
}

