@import "_variables.scss";

.splash-header {
  text-align: center;
  background: #F0F1F1;
  padding: 20px 0;
  margin: 0 auto 20px;
  @include tablet {
    padding: 30px 0;
    margin: 0 auto 30px;
  }
  @include desktop {
    padding: 40px 0;
    margin: 0 auto 40px;
  }

  h1 {
    color: #000;
    font: 700 24px/1em $opensans;
    margin: 0 auto 10px;
    @include tablet {
      font-size: 40px;
    }
    @include desktop {
      margin: 0 auto 15px;
    }
    @include large-desktop {
      font-size: 50px;
    }
  }

  p {
    font: 400 15px/1.5em $opensans;
    color: #000;
    margin: 0 auto 20px;
    @include tablet {
      font-size: 16px;
      margin: 0 auto 40px;
    }
    @include large-desktop {
      font-size: 17px;
    }
  }

  .tile-wrap {

    padding: 0 7px;
    @include tablet {
      padding: 0;
    }

    .bg-tile {
      display: block;
      background: #000;
      text-decoration: none;
      color: #FFF;
      border-radius: 5px;
      text-shadow: 0 0 15px #000;
      position: relative;
      padding: 40px 10px;
      overflow: hidden;
      margin: 0 auto 15px;
      @include tablet {
        margin: 0 auto 30px;
      }
      @include desktop {
        padding: 50px 15px;
      }
      @include large-desktop {
        padding: 60px 15px;
      }

      .background-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: #000 center center/cover no-repeat;
        transition: transform 4s ease;
        transform: scale(1.01);
        @include desktop {
          padding: 50px 15px;
        }
        @include large-desktop {
          padding: 60px 15px;
        }
      }

      &.large {
        padding: 80px 10px;
        @include tablet {
          padding: 107px 10px;
        }
        @include desktop {
          padding: 135px 15px;
        }
        @include large-desktop {
          padding: 156px 15px;
        }
      }

      &:hover .background-image {
        @include desktop {
          transform: scale(1.11);
        }
      }

      h2 {
        font: 800 17px/1em $opensans;
        margin: 0 auto 7px;
        color: #FFF;
        position: relative;
        z-index: 2;
        @include desktop {
          font-size: 25px;
          margin: 0 auto 15px;
        }
        @include large-desktop {
          font-size: 27px;
        }
      }

      p {
        font: 400 12px/1.3em $opensans;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        color: #FFF;
        @include desktop {
          font-size: 14px;
        }
      }

      .badge {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: $glYellow;
        text-shadow: none;
        padding: 5px 7px;
        border-radius: 5px 0 5px 0;
        font: 700 12px/1em $robotoCondensed;
        text-transform: uppercase;
        color: #000;
      }
    }
  }
}

//.lead-gen {
//
//  .starter-kit {
//    display: flex;
//    flex-direction: row;
//    padding: 30px 0;
//    background: #1c1e1e;
//    border-radius: 5px;
//    font-size: 0;
//    @include desktop {
//      padding: 50px 30px;
//    }
//    @include large-desktop {
//      padding: 60px 50px;
//    }
//
//    .image {
//      display: none;
//      flex-direction: column;
//      justify-content: center;
//      position: relative;
//      width: 200px;
//      min-width: 200px;
//
//      @include desktop {
//        display: inline-block;
//      }
//      @include large-desktop {
//        width: 220px;
//        min-width: 220px;
//      }
//
//      img {
//        position: absolute;
//        top: 50%;
//        left: 50%;
//        width: 100%;
//        max-width: 100%;
//        height: auto;
//        border: 7px solid #FFF;
//        border-radius: 50%;
//        transform: translate(-50%, -50%);
//      }
//    }
//
//    .form {
//      flex-direction: column;
//      justify-content: center;
//      flex-grow: 1;
//      padding: 0 30px;
//      position: relative;
//      overflow: hidden;
//      text-align: center;
//
//      @include desktop {
//        padding: 0 0 0 30px;
//      }
//    }
//
//    .logo {
//      margin: 0 auto 7px;
//      width: 100%;
//      @include tablet {
//        max-width: 490px;
//        margin: 0 auto 15px;
//      }
//      @include desktop {
//        max-width: 570px;
//      }
//    }
//
//    h2 {
//      font: 700 28px/1em $robotoCondensed;
//      text-transform: uppercase;
//      color: #FFF;
//      margin-top: 0;
//      margin-bottom: 10px;
//
//      @include tablet {
//        font-size: 44px;
//        margin-bottom: 5px;
//      }
//      @include desktop {
//        font-size: 46px;
//      }
//      @include large-desktop {
//        font-size: 56px;
//      }
//    }
//
//    p {
//      font: 400 15px/1em $opensans;
//      color: #FFF;
//      margin-bottom: 20px;
//      @include large-desktop {
//        font-size: 18px;
//      }
//    }
//
//    form {
//      div {
//        display: none;
//      }
//    }
//
//    input, button {
//      border-radius: 5px;
//      height: 50px;
//      border: none;
//      outline: none;
//      padding: 0 15px;
//      display: block;
//
//      @include tablet {
//        display: inline-block;
//      }
//    }
//
//    input {
//      width: 100%;
//      color: #000;
//      margin-bottom: 10px;
//      font: 400 20px/1em $opensans;
//
//      @include tablet {
//        width: calc(100% - 310px);
//        margin-right: 10px;
//        margin-bottom: 0;
//      }
//    }
//
//    button {
//      width: 100%;
//      background: #ff892c;
//      color: #FFF;
//      text-transform: uppercase;
//      font: 700 20px/1em $robotoCondensed;
//      transition: background .1s ease-in-out;
//
//      @include tablet {
//        width: 300px;
//      }
//
//      @include desktop {
//        &:hover {
//          background: lighten(#ff892c, 10%);
//        }
//      }
//    }
//  }
//}

.see-all {
  text-transform: uppercase;
  color: #000;
  font: 700 14px/1em $robotoCondensed;
  border-radius: 5px;
  padding: 6px 13px;
  transition: box-shadow .3s;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  display: inline;
  float: right;
  @include tablet {
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  &.mobile {
    display: inline-block;
    margin: -15px auto 15px;
    float: none;
  }
}

.courses {
  padding: 30px 0 15px;
  @include tablet {
    padding: 30px 0 0;
  }

  h3 {
    margin: 0 auto 20px;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .tile-wrap {

    padding: 0 7px;
    @include tablet {
      padding: 0;
    }

    .course-tile {
      border-radius: 5px;
      color: #fff;
      width: 100%;
      padding: 40px 0;
      display: block;
      position: relative;
      text-transform: uppercase;
      text-align: center;
      text-decoration: none;
      margin: 0 auto 15px;
      @include tablet {
        padding: 60px 0;
        margin: 0 auto 30px;
      }
      @include desktop {
        padding: 70px 0;
      }

      &:hover {
        opacity: 0.9;
      }

      .badge {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: $glYellow;
        text-shadow: none;
        padding: 5px 7px;
        border-radius: 5px 0 5px 0;
        font: 700 12px/1em $robotoCondensed;
        text-transform: uppercase;
        color: #fff;
      }

      h1 {
        font: 700 26px/0.9em $robotoCondensed;
        margin: 0 auto;
        @include tablet {
          font-size: 40px;
        }
      }

      p {
        font: 700 12px/1em $robotoCondensed;
        margin: 0 auto;
        @include tablet {
          font-size: 16px;
        }
      }
    }
  }
}

.category-list {
  h3 {
    margin: 0 auto 20px;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

}

iframe.thank-you {
  width: 100%;
  max-width: 960px;
  border-radius: 5px;
  height: 0;
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all .4s ease-in;
  display: block;
  margin: 0 auto;
  background: #FFF;

  &.active {
    max-height: 100%;
    height: 340px;
    visibility: visible;
    opacity: 1;
    @include tablet {
      height: 350px;
    }
  }
}

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%;
}

.small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
  clear: both;
}

.small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
  float: left;
}

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%;
}

.small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
  clear: both;
}

.small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
  float: left;
}

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%;
}

.small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
  clear: both;
}

.small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
  float: left;
}

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%;
}

.small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
  clear: both;
}

.small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
  float: left;
}

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%;
}

.small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
  clear: both;
}

.small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
  float: left;
}

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%;
}

.small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
  clear: both;
}

.small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
  float: left;
}

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%;
}

.small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
  clear: both;
}

.small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
  float: left;
}

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%;
}

.small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
  clear: both;
}

.small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
  float: left;
}

@media (min-width: 640px) {
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
    float: left;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
    float: left;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
    float: left;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
    float: left;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
    float: left;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
    float: left;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
    float: left;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
    float: left;
  }
}

@media (min-width: 1024px) {
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
    float: left;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
    float: left;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
    float: left;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
    float: left;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
    float: left;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
    float: left;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
    float: left;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
    float: left;
  }
}