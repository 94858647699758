@charset "UTF-8";

/* drumeo */
@font-face {
  font-family: "drumeo";
  src:url("https://file.myfontastic.com/Eyyv7p9PaRPfiSA2iGFy8B/fonts/1496169817.eot");
  src:url("https://file.myfontastic.com/Eyyv7p9PaRPfiSA2iGFy8B/fonts/1496169817.eot?#iefix") format("embedded-opentype"),
  url("https://file.myfontastic.com/Eyyv7p9PaRPfiSA2iGFy8B/fonts/1496169817.woff") format("woff"),
  url("https://file.myfontastic.com/Eyyv7p9PaRPfiSA2iGFy8B/fonts/1496169817.ttf") format("truetype"),
  url("https://file.myfontastic.com/Eyyv7p9PaRPfiSA2iGFy8B/fonts/1496169817.svg#1477933762") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "drumeo", sans-serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font: normal normal normal 14px/1 "drumeo", sans-serif;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "drumeo", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-bg-check:before {
  content: "\62";
}
.icon-courses:before {
  content: "\43";
}
.icon-for-teachers:before {
  content: "\54";
}
.icon-download:before {
  content: "\64";
}
.icon-faculty:before {
  content: "\74";
}
.icon-forum:before {
  content: "\46";
}
.icon-songs:before {
  content: "\78";
}
.icon-library:before {
  content: "\4c";
}
.icon-loops:before {
  content: "\6f";
}
.icon-metronome-beep:before {
  content: "\4d";
}
.icon-packs:before {
  content: "\70";
}
.icon-resources:before {
  content: "\72";
}
.icon-satellite:before {
  content: "\58";
}
.icon-student:before {
  content: "\55";
}
.icon-student-focus:before {
  content: "\53";
}
.icon-find-teacher:before {
  content: "\66";
}
.icon-tools:before {
  content: "\52";
}
.icon-play-alongs:before {
  content: "\50";
}
.icon-schedule:before {
  content: "\63";
}
.icon-search:before {
  content: "\73";
}
.icon-live:before {
  content: "\6c";
}
.icon-approved-teacher:before {
  content: "\61";
}
.icon-home:before {
  content: "\48";
}
.icon-metronome:before {
  content: "\6d";
}
.icon-drums:before {
  content: "\44";
}
.icon-learning-paths:before {
  content: "\65";
}